.tag {
  display: block;
  position: relative;
  height: 6.7rem;
  min-width: 15rem;
  backface-visibility: hidden;
  opacity: 1;
  transition: all 300ms cubic-bezier(0.4, 0, 0.6, 1);
  color: #000000;
}
.tag.tag--link {
  color: #3ead47;
}
.tag.tag--link:hover,
.tag.tag--link:focus {
  color: #1f7d39;
}
.tag__translation {
  display: block;
  margin-top: 1.6rem;
}
.tag__counter {
  margin-left: 1rem;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 34px;
  line-height: 3.4rem;
}
.tag__title {
  font-weight: 300;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 34px;
  line-height: 3.4rem;
}
