.color {
  position: relative;
  float: left;
  width: 15rem;
  height: 20rem;
  margin: 0 2rem 2rem 0;
}
.color:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 150px;
  background-color: #3ead47;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.color p {
  position: relative;
  margin-top: 160px;
  z-index: 1;
  text-align: center;
}
.color--accent {
  position: relative;
  float: left;
  width: 15rem;
  height: 20rem;
  margin: 0 2rem 2rem 0;
}
.color--accent:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 150px;
  background-color: #d00202;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
