@media (max-width: 992px) {
  .reminderlist {
    width: 100%;
    min-height: 400px;
    max-height: 100%;
    overflow: auto;
    margin-bottom: 1.6rem;
  }
}
.reminderlist.reminderlist--active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.reminderlist.reminderlist--alt {
  width: 100%;
  margin-bottom: 1.6rem;
}
.close {
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
}
