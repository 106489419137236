.tagcontainer {
  opacity: 0;
  transform: translate3d(0, -74px, 0);
  transition:
    transform 300ms cubic-bezier(0.4, 0, 0.6, 1),
    opacity 300ms cubic-bezier(0.4, 0, 0.6, 1);
}
@media (max-width: 992px) {
  .tagcontainer {
    padding: 0;
  }
}
@media lg, (min-width: 992px) {
  .tagcontainer {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    transform: translate3d(0, -100%, 0);
  }
}
@media xl, (min-width: 1200px) {
  .tagcontainer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media lg, (min-width: 992px) {
  .tagcontainer:first-child {
    padding-left: 1.25rem;
  }
}
@media lg, (min-width: 992px) {
  .tagcontainer:last-child {
    padding-right: 1.25rem;
  }
}
@media (max-width: 992px) {
  .tagcontainer.tagcontainer--alt {
    display: none;
    padding: 0 1rem;
  }
}
@media (max-width: 992px) {
  .tagcontainer.tagcontainer--active {
    display: block;
  }
}
@media (max-width: 992px) {
  .tagcontainer.tagcontainer--compact {
    margin-right: 0;
    margin-left: 0;
  }
}
.tagcontainer.tagcontainer--inactive {
  transform: translate3d(0, 0, 0) scale3d(1, 0, 1);
  opacity: 0;
  pointer-events: none;
  z-index: -2;
}
.tagcontainer.tagcontainer--selected {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  opacity: 1;
  z-index: 1;
}
@media (max-width: 992px) {
  .tagcontainer.tagcontainer--selected {
    padding-left: 0;
    padding-right: 0;
  }
}
.tagcontainer.tagcontainer--selected:nth-child(2) {
  transform: translate3d(0, -74px, 0) scale3d(1, 1, 1);
}
.tagcontainer.tagcontainer--selected:nth-child(3) {
  transform: translate3d(0, -148px, 0) scale3d(1, 1, 1);
}
.tagcontainer.tagcontainer--selected:nth-child(4) {
  transform: translate3d(0, -222px, 0) scale3d(1, 1, 1);
}
.tagcontainer.tagcontainer--selected:nth-child(5) {
  transform: translate3d(0, -296px, 0) scale3d(1, 1, 1);
}
