@import '~normalize-css/normalize.css';
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
.clear {
  clear: both;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
  will-change: transform;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.flex {
  display: flex;
  flex: 1;
}
.flex--center {
  align-items: center;
}
.invisible {
  visibility: hidden;
}
.type--center {
  text-align: center;
}
.type--right {
  text-align: right;
}
.type--left {
  text-align: left;
}
@keyframes card__stack {
  from {
    transform: translate3d(0, 25%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slide__tozero {
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade__in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scale__up {
  0% {
    transform: scale3d(0, 0, 0);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bellshake {
  0% {
    transform: rotate(0) scale3d(0, 0, 0);
  }
  10% {
    transform: scale3d(1, 1, 1);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes shake {
  8%,
  41% {
    transform: translateX(-10px);
  }
  25%,
  58% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}
@keyframes slide__up {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide__down {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes translate {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes avatar__flagup {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0) scale3d(1.7, 1.7, 1.7);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1.7, 1.7, 1.7);
  }
}
@keyframes slide__leftin {
  from {
    opacity: 0;
    transform: translate3d(-25%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide__rightin {
  from {
    opacity: 0;
    transform: translate3d(25%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes flip {
  from {
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0);
  }
}
@keyframes scale__upsmall {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.animated {
  animation-duration: 300ms;
}
@media (min-width: 768px) {
  .animated {
    animation-duration: 390ms;
  }
}
@media (min-width: 992px) {
  .animated {
    animation-duration: 200ms;
  }
}
.animated.animated--onload {
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.animated.animated--scaleup {
  animation-name: scale__up;
}
.animated.animated--scaleupsmall {
  animation-name: scale__upsmall;
}
.animated.animated--bellshake {
  backface-visibility: hidden;
  transform-origin: top center;
  animation-name: bellshake;
  animation-duration: 580ms;
  animation-fill-mode: both;
}
.animated.animated--fadein {
  animation-name: fade__in;
}
.animated.animated--delayed {
  animation-delay: 420ms;
}
.animated.animated--delayed1 {
  animation-delay: 520ms;
}
.animated.animated--delayed2 {
  animation-delay: 620ms;
}
.animated.animated--delayed3 {
  animation-delay: 720ms;
}
.animated.animated--delayed4 {
  animation-delay: 820ms;
}
.animated.animated--delayed5 {
  animation-delay: 920ms;
}
.animated.animated--delayed6 {
  animation-delay: 1020ms;
}
.animated.animated--delayed7 {
  animation-delay: 1120ms;
}
.animated.animated--delayed8 {
  animation-delay: 1220ms;
}
.animated.animated--delayed9 {
  animation-delay: 1320ms;
}
.animated.animated--delayed10 {
  animation-delay: 1420ms;
}
.anim__fade--in {
  animation-name: fade__in;
}
.anim__slide--up {
  animation-name: translate;
  animation-duration: 600ms;
}
.anim__slide--upquick {
  animation-name: translate;
  animation-duration: 290ms;
}
.anim__slide--down {
  animation-name: translate;
  animation-duration: 220ms;
}
@media (min-width: 992px) {
  .anim__slide--down {
    animation-duration: 420ms;
  }
}
.anim__shake {
  animation: shake 0.5s linear;
}
.anim__flagup {
  animation-name: avatar__flagup;
  animation-duration: 600ms;
}
.anim__slide--leftin {
  animation-name: slide__leftin;
}
.anim__slide--rightin {
  animation-name: slide__rightin;
}
.anim__flip {
  animation-name: flip;
  animation-duration: 220ms;
}
body {
  margin: 0;
  background-color: #fafafa;
}
body:before {
  position: absolute;
  left: -100%;
  top: -100%;
  height: 0;
  width: 0;
  visibility: hidden;
  content: 'xs';
}
@media sm, (min-width: 480px) {
  body:before {
    content: 'sm';
  }
}
@media md, (min-width: 768px) {
  body:before {
    content: 'md';
  }
}
@media lg, (min-width: 992px) {
  body:before {
    content: 'lg';
  }
}
@media xl, (min-width: 1200px) {
  body:before {
    content: 'xl';
  }
}
* {
  box-sizing: border-box;
}
@media (max-width: 992px) {
  img {
    max-width: 100%;
    width: auto\9;
    height: auto;
    vertical-align: middle;
    border: 0;
  }
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main {
    display: block;
  }
}
.app {
  overflow: hidden;
  display: flex;
  flex: 1;
  background-color: #fafafa;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .app {
    display: block;
  }
}
@media print {
  .app {
    padding-top: 0;
  }
}
.app.app--static {
  flex-direction: column;
}
.appheader {
  position: fixed;
  height: 7.8rem;
  width: 100%;
  z-index: 999;
}
.header--static {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 7.8rem;
  background-color: #3ead47;
  box-shadow:
    0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.14),
    0 0.1rem 1rem 0 rgba(0, 0, 0, 0.12),
    0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2);
}
.header--static .header__logo {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.8rem;
  min-width: 9.6rem;
  padding: 1rem 0;
  text-align: center;
  background-color: #ffffff;
}
.header--static .header__logo img {
  position: relative;
  max-height: 100%;
  height: auto;
}
.page--static {
  display: flex;
  flex: 1;
  padding-top: 4rem;
  background-color: #fff;
}
.page--vcenter {
  align-items: center;
}
div.nojs__message {
  display: none;
}
.nojs__loader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
.nojs__loader > div {
  float: none;
}
.no-js div.nojs__message {
  display: block;
}
.no-js div.nojs__loader {
  display: none;
}
.no-js .app {
  flex-direction: column;
}
.no-js .page {
  display: flex;
  flex: 1;
}
.sentry-error-embed {
  height: 60vh;
  margin-top: 20vh;
}
.sentry-error-embed h2 span {
  font-size: inherit;
}
.sentry-error-embed .form-submit .close {
  position: static;
}
html {
  font-size: 62.5%;
}
@media print {
  html {
    font-size: 45%;
  }
}
body {
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
  line-height: 2rem;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('../branding/enroller/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('../branding/enroller/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('../branding/enroller/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('../branding/enroller/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('../branding/enroller/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('../branding/enroller/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Lato';
  src:
    local('Lato'),
    url('../branding/greenheart/Lato-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Lato';
  src:
    local('Lato'),
    url('../branding/greenheart/Lato-LightItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src:
    local('Lato'),
    url('../branding/greenheart/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Lato';
  src:
    local('Lato'),
    url('../branding/greenheart/Lato-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src:
    local('Lato'),
    url('../branding/greenheart/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src:
    local('Lato'),
    url('../branding/greenheart/Lato-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}
.h1,
.h2,
.h3,
.h4 {
  color: #414141;
}
.h1 {
  font-size: 3rem;
  line-height: 3.8rem;
}
@media (min-width: 992px) {
  .h1 {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}
h1 {
  color: #414141;
  font-size: 3rem;
  line-height: 3.8rem;
}
@media (min-width: 992px) {
  h1 {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}
.h2 {
  font-size: 3rem;
  line-height: 3.6rem;
}
h2 {
  color: #414141;
  font-size: 3rem;
  line-height: 3.6rem;
}
.h3 {
  font-size: 2.4rem;
  line-height: 2.6rem;
}
h3 {
  color: #414141;
  font-size: 2.4rem;
  line-height: 2.6rem;
  margin-top: 3.2rem;
}
.h4 {
  font-size: 1.8rem;
  line-height: 2rem;
}
h4 {
  color: #414141;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-top: 3.2rem;
}
p {
  margin-top: 0;
  margin-bottom: 1.8rem;
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-weight: 300;
  color: #414141;
}
a {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
a:hover {
  color: #1f7d39;
  cursor: pointer;
}
small {
  color: #414141;
  font-size: 1.1rem;
  line-height: 1.6rem;
}
dl {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0;
  padding: 2rem 0;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #414141;
}
dt {
  overflow: hidden;
  flex: 0 0 18.8rem;
  font-weight: bold;
}
dd {
  flex: 65% 0 0;
  max-width: 65%;
  margin: 0 0 1rem 0;
}
@media lg, (min-width: 992px) {
  dd {
    padding-left: 2rem;
  }
}
dd + dd {
  margin-left: 35%;
}
.type--flat {
  margin: 0;
}
.type--intro {
  font-size: 2.4rem;
  line-height: 3.4rem;
  color: #000000;
}
.type--translation {
  font-weight: 300;
  color: #666666;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.type--footer {
  color: #505050;
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-bottom: 0;
}
.type--strong {
  font-weight: bold;
  display: block;
}
.type--sectionlabel {
  margin-bottom: 1.2rem;
}
@media (min-width: 992px) {
  .type--sectionlabel {
    margin-top: 0;
  }
}
.type--nomargin {
  margin: 0;
}
.type--wide {
  max-width: 100%;
}
.type--error {
  color: #f44336;
  font-size: 1.3rem;
}
.type--success {
  color: #3ead47;
  font-size: 1.3rem;
}
.header {
  height: 7.8rem;
  background-color: #1f1f8c;
  width: 100%;
  box-shadow:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.header__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 3.6rem;
  padding-bottom: 2rem;
}
@media md, (min-width: 768px) {
  .header__container {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 3rem;
  }
}
.footer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.5rem;
  width: 100%;
  height: 10rem;
  background-color: #fff;
  border-top: 0.1rem solid #e7e7e7;
}
@media (min-width: 768px) {
  .footer {
    flex-direction: row;
    align-items: center;
    padding: 2.7rem 10.7rem 2.7rem 2.7rem;
  }
}
.footer__brand {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.footer__brand p {
  margin-left: 1.8rem;
}
@media (min-width: 768px) {
  .footer__brand {
    margin-bottom: 0;
  }
}
.footer__nav {
  margin: 0;
  padding: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .footer__nav {
    width: auto;
  }
}
.footer__navitem {
  float: left;
  margin-left: 1.8rem;
}
.footer__navitem:first-child {
  margin-left: 0;
}
.footer__navitem a {
  color: #3ead47;
  font-size: 1.3rem;
  transition: color 0.3s ease-in-out;
}
.footer__navitem a:hover,
.footer__navitem a:focus {
  color: #1f7d39;
}
.fade-enter {
  opacity: 0;
  transition: opacity 500ms cubic-bezier(0, 0, 0.2, 1);
  will-change: opacity;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.4, 0, 1, 1);
  will-change: opacity;
}
.fade-exit {
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.4, 0, 1, 1);
  will-change: opacity;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms cubic-bezier(0, 0, 0.2, 1);
  will-change: opacity;
}
.slidedown-enter {
  opacity: 0;
  transform: translateY(-100%);
  transition:
    opacity 1000ms cubic-bezier(0, 0, 0.2, 1),
    transform 500ms cubic-bezier(0, 0, 0.2, 1);
  will-change: opacity, transform;
}
.slidedown-enter.slidedown-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition:
    opacity 1000ms cubic-bezier(0, 0, 0.2, 1),
    transform 500ms cubic-bezier(0, 0, 0.2, 1);
  will-change: opacity, transform;
}
.slidedown-exit {
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0, 0, 0.2, 1);
  will-change: opacity;
}
.slidedown-exit.slidedown-exit-active {
  opacity: 0;
  transition: opacity 500ms cubic-bezier(0, 0, 0.2, 1);
  will-change: opacity;
}
table,
.table {
  margin: 0 0 1rem 0;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  border: 0;
  border-collapse: collapse;
}
table thead,
.table thead {
  display: table-row-group;
}
table th,
.table th {
  text-align: left;
  padding: 0 2rem 0 0;
  white-space: nowrap;
}
table th:last-child,
.table th:last-child {
  padding-right: 0;
}
table td,
.table td {
  padding: 1rem 2rem 1rem 0;
}
table td:last-child,
.table td:last-child {
  padding-right: 0;
}
@media (max-width: 992px) {
  table.table--flip,
  .table.table--flip {
    display: block;
    position: relative;
    width: 100%;
    border: 1px solid #3ead47;
  }
  table.table--flip thead,
  .table.table--flip thead,
  table.table--flip tbody,
  .table.table--flip tbody,
  table.table--flip tr,
  .table.table--flip tr,
  table.table--flip th,
  .table.table--flip th,
  table.table--flip td,
  .table.table--flip td {
    display: block;
  }
  table.table--flip thead,
  .table.table--flip thead {
    float: left;
  }
  table.table--flip thead th,
  .table.table--flip thead th {
    text-align: right;
  }
  table.table--flip tbody,
  .table.table--flip tbody {
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  table.table--flip tbody tr,
  .table.table--flip tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  table.table--flip thead,
  .table.table--flip thead {
    border-right: 1px solid #3ead47;
    -webkit-box-shadow: 0.3rem 0 1rem rgba(0, 0, 0, 0.07);
    --moz-box-shadow: 0.3rem 0 1rem rgba(0, 0, 0, 0.07);
    box-shadow: 0.3rem 0 1rem rgba(0, 0, 0, 0.07);
  }
  table.table--flip thead th,
  .table.table--flip thead th {
    text-align: right;
    line-height: 3.8rem;
    padding: 0 1rem;
  }
  table.table--flip thead th:last-child,
  .table.table--flip thead th:last-child {
    padding-right: 1rem;
  }
  table.table--flip tbody,
  .table.table--flip tbody {
    padding: 0 1rem;
  }
  table.table--flip tbody td,
  .table.table--flip tbody td {
    line-height: 3.8rem;
    text-align: left;
    padding: 0 1rem;
  }
  table.table--flip tbody td:last-child,
  .table.table--flip tbody td:last-child {
    text-align: left;
  }
}
.logo {
  width: 100%;
  height: auto;
}
.logo.logo--footer {
  width: 133px;
}
.stepcounter {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 348px;
  transition:
    opacity 300ms cubic-bezier(0.4, 0, 0.6, 1),
    transform 300ms cubic-bezier(0.4, 0, 0.6, 1);
}
@media (max-width: 992px) {
  .tag--compact .stepcounter {
    opacity: 0;
    transform: scale3d(1, 0, 1);
  }
}
.stepcounter stop {
  transition: all 300ms cubic-bezier(0.4, 0, 0.6, 1);
}
.tag--link:hover .stepcounter stop {
  stop-color: #1f7d39;
}
.stepcounter__line {
  stroke-dashoffset: 0;
  opacity: 0;
}
.icon.icon--bell {
  transform: scale3d(0, 0, 0);
  animation-delay: 420ms;
}
.icon.icon--accordion {
  transition: transform 220ms cubic-bezier(0.4, 0, 0.6, 1);
}
.is-closed .icon.icon--accordion {
  transform: rotate(180deg);
}
.icon.icon--edit {
  width: 2rem;
  height: 2rem;
  fill: #4a4a4a;
}
.icon.icon--editstep {
  fill: #4a4a4a;
}
.icon.icon--handle {
  fill: #e7e7e7;
}
.icon.icon--settings {
  width: 2.4rem;
  height: 4rem;
}
.icon.icon--payment {
  height: 2.4rem;
  max-width: 3rem;
  margin-right: 1rem;
  fill: #888888;
}
.icon.icon--payment:last-child {
  margin-right: 0;
}
@media md, (min-width: 768px) {
  .icon.icon--payment {
    max-width: 4rem;
    margin-right: 2rem;
  }
}
.icon.icon--paymentstatus {
  height: 2rem;
}
.icon.icon--add {
  fill: #ffffff;
}
.icon.icon--remove,
.icon.icon--option,
.icon.icon--copy,
.icon.icon--delete {
  fill: #4a4a4a;
}
.columncontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
@media (min-width: 992px) {
  .columncontainer {
    flex-direction: row;
  }
}
.columncontainer.columncontainer--active {
  min-height: 100vh;
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.column__wrapper {
  position: relative;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 992px) {
  .column__wrapper {
    overflow-x: hidden;
  }
}
.column__holder {
  transition:
    opacity 0.3s ease-out,
    filter 0.3s ease-out;
  will-change: opacity, filter;
}
.column__holder:before,
.column__holder:after {
  content: '';
  position: absolute;
  top: 0;
  width: 4.5rem;
  height: 100%;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-out;
  will-change: opacity;
}
@media lg, (min-width: 992px) {
  .column__holder:before,
  .column__holder:after {
    display: none;
  }
}
.column__holder.column__holder--blur {
  filter: blur(5px);
  opacity: 0.1;
  pointer-events: none;
}
.column__holder.column__holder--absolute {
  width: 100%;
  position: absolute;
  top: 9.7rem;
  left: 0;
}
.column__holder:before {
  left: 0;
  background-image: linear-gradient(to left, rgba(250, 250, 250, 0), #fafafa);
}
.column__holder:after {
  right: 0;
  background-image: linear-gradient(to right, rgba(250, 250, 250, 0), #fafafa);
}
.column__holder.column__holder--start:before {
  opacity: 0;
}
.column__holder.column__holder--end:after {
  opacity: 0;
}
.contentcolumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .contentcolumn {
    position: relative;
    min-width: 18rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .contentcolumn {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.contentcolumn:first-child {
  margin-left: 0;
}
@media (min-width: 992px) {
  .contentcolumn:first-child {
    padding-left: 1.25rem;
  }
}
.contentcolumn:last-child {
  margin-right: 0;
}
@media (min-width: 992px) {
  .contentcolumn:last-child {
    padding-right: 1.25rem;
  }
}
@media (max-width: 992px) {
  .contentcolumn.contentcolumn--wrapper {
    background: #f3f3f3;
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
    opacity: 1;
    border-radius: 8px;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
}
@media (min-width: 992px) {
  .contentcolumn.contentcolumn--wrapper {
    background-color: transparent;
    margin: 0 auto;
  }
}
.contentcolumn.contentcolumn--active {
  opacity: 1;
  z-index: 3;
}
.contentcolumn.contentcolumn--selected {
  opacity: 1;
  background-color: transparent;
}
.content__columncontent {
  flex: 1;
  width: 100%;
  margin-bottom: 0;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-radius: 0.8rem;
  background: #f3f3f3;
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.contentcolumn--active .content__columncontent {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.stages {
  position: relative;
  width: 100%;
  padding-top: 7.2rem;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .stages {
    flex-direction: row;
  }
}
.stage {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
  animation-name: card__stack;
  animation-duration: 330ms;
  animation-delay: 460ms;
  transform: translate3d(0, 25%, 0);
  opacity: 0;
}
.stage:nth-child(2) {
  animation-delay: 500ms;
}
.stage:nth-child(3) {
  animation-delay: 580ms;
}
.stage:nth-child(4) {
  animation-delay: 660ms;
}
.stage:last-child {
  animation-delay: 720ms;
}
.stage__title {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #414141;
  letter-spacing: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.stage__tag {
  margin-top: 0;
  margin-bottom: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.stage__copy {
  margin-top: 4rem;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.ticket {
  display: block;
  position: relative;
  transform: translate3d(0, 25%, 0);
  opacity: 0;
  filter: drop-shadow(0 0.1rem 0.3rem rgba(0, 0, 0, 0.3));
}
@media lg, (min-width: 992px) {
  .ticket {
    filter: drop-shadow(0 0.7rem 0.5rem rgba(0, 0, 0, 0.2));
  }
}
.ticket:not(:first-child) {
  margin-top: 1rem;
}
.ticket.ticket--loading {
  min-height: 12.4rem;
  transform: none;
  opacity: 1;
  transition: none;
}
.view--list .ticket {
  min-height: 4.5rem;
}
.ticket__card {
  width: 100%;
  padding: 1rem 1.8rem 1.5rem 1.8rem;
  transition: padding 0.3s ease-out;
  background: #ffffff;
  clip-path: polygon(100% 0, 100% calc(100% - 2.5rem), calc(100% - 2.5rem) 100%, 0 100%, 0 0);
}
.view--list .ticket__card {
  padding: 0 1.3rem 0 1.3rem;
  clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
}
.ticket__content {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem;
}
.ticket__title {
  overflow: hidden;
  margin: 0;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000000;
  transform: translate3d(0, 25%, 0);
  opacity: 0;
}
.ticket--loading .ticket__title {
  opacity: 1;
  transform: none;
}
.ticket__title strong {
  font-weight: 800;
  transform: translate3d(0, 25%, 0);
  opacity: 0;
}
.ticket--loading .ticket__title strong {
  opacity: 1;
  transform: none;
}
.view--card .ticket__title strong {
  display: block;
}
.view--list .ticket__title strong:before {
  content: ' ';
}
.ticket__title--highlighted {
  color: #29a64b;
}
.ticket__status {
  width: 100%;
  height: 0.2rem;
  margin: 1rem 0 1.2rem;
  background-color: #9e9e9e;
}
.view--list .ticket__status {
  position: absolute;
  left: 0;
  top: 0.1rem;
  height: 4.3rem;
  width: 0.2rem;
  margin: 0;
}
.ticket__details {
  display: flex;
  align-items: center;
  opacity: 1;
  height: auto;
  transition: opacity 0.3s ease-out;
}
.view--list .ticket__details {
  opacity: 0;
  height: 0;
}
.ticket__permanence {
  min-width: 0;
  margin-left: auto;
  font-weight: bold;
  transform: translate3d(0, 100%, 0);
  white-space: nowrap;
  opacity: 0;
  line-height: 2rem;
}
.ticket__action {
  min-width: 0;
  max-width: 70%;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #414141;
  transform: translate3d(0, 100%, 0);
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket--loading .ticket__action {
  opacity: 1;
  transform: none;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.placeholder {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3.9rem;
}
.placeholder.placeholder--static,
.placeholder.placeholder--staticlong,
.placeholder.placeholder--profile,
.placeholder.placeholder--profilelong,
.placeholder.placeholder--column,
.placeholder.placeholder--breadcrumb {
  padding-top: 0;
}
.placeholder.placeholder--circle {
  width: 2rem;
  padding-top: 0;
}
.placeholder__element {
  position: relative;
  height: 4.1rem;
  width: 42rem;
  margin-bottom: 2rem;
  background: #f6f7f8;
}
.placeholder--animate .placeholder__element {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderShimmer;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}
.placeholder--column .placeholder__element {
  height: 10rem;
  width: 100%;
  border-radius: 0.8rem;
}
.placeholder--profile .placeholder__element {
  height: 1.8rem;
  width: 12rem;
  margin-bottom: 0.9rem;
}
.placeholder--breadcrumb .placeholder__element {
  height: 1.8rem;
  width: 12rem;
  margin-bottom: 0;
}
.placeholder--profilelong .placeholder__element {
  height: 1.8rem;
  width: 20rem;
  margin-bottom: 0.9rem;
}
.placeholder--static .placeholder__element {
  height: 1.6rem;
  width: 7rem;
  margin-bottom: 0.4rem;
}
.placeholder--staticlong .placeholder__element {
  height: 1.6rem;
  width: 14rem;
  margin-bottom: 0.4rem;
}
.placeholder--circle .placeholder__element {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}
.placeholder--checkbox .placeholder__element {
  height: 2rem;
  width: 2rem;
}
.placeholder--checkboxset .placeholder__element,
.placeholder--radiogroup .placeholder__element {
  height: 2rem;
  width: 21rem;
}
.placeholder--content .placeholder__element {
  height: 2rem;
  width: 100%;
}
.placeholder--separator .placeholder__element {
  height: 0.2rem;
  width: 100%;
}
.placeholder--file .placeholder__element {
  height: 17.6rem;
  width: 60rem;
}
.placeholder--textarea .placeholder__element {
  height: 13.7rem;
  width: 42rem;
}
.placeholder--startandenddate .placeholder__element:after,
.placeholder--monthyear .placeholder__element:after,
.placeholder--localtime .placeholder__element:after {
  content: '';
  position: absolute;
  left: 50%;
  height: 100%;
  width: 2rem;
  transform: translateX(-50%);
  background-color: #fff;
}
.view--list .placeholder.placeholder--circle .placeholder__element {
  margin-bottom: 0;
}
.parsedmessage h1 {
  margin-top: 0;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #d8d8d8;
}
.parsedmessage h2,
.parsedmessage h3,
.parsedmessage h4 {
  margin-top: 0;
}
.parsedmessage p {
  margin-bottom: 1.8rem;
}
.parsedmessage strong,
.parsedmessage b {
  font-weight: bold;
}
.parsedmessage em,
.parsedmessage i {
  font-style: italic;
}
.parsedmessage ins,
.parsedmessage u {
  text-decoration: underline;
}
.parsedmessage ul {
  list-style: disc inside;
}
.parsedmessage ol {
  padding: 0;
  list-style-position: inside;
}
.parsedmessage a {
  color: #3ead47;
}
.parsedmessage a:hover,
.parsedmessage a:focus {
  color: #1f7d39;
}
.parsedmessage a:visited {
  color: #3ead47;
}
.parsedmessage blockquote {
  margin: 0 0 1.8rem 0;
  padding-left: 2rem;
  border-left: 0.2rem solid #e7e7e7;
  line-height: 2.4rem;
  color: #414141;
}
.profile__content {
  font-size: 1.6rem;
}
.profile__term {
  font-weight: bold;
  white-space: nowrap;
}
.profile__description {
  white-space: nowrap;
}
.translation {
  display: inline-block;
  width: 100%;
  margin: 0;
  font-weight: 300;
  color: #666666;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.translation span {
  font-size: inherit;
}
.translation__content {
  margin-bottom: 0;
}
.translation__content [lang='en'] {
  display: none;
}
.country {
  position: relative;
  display: flex;
  align-items: center;
}
.view--list .country {
  min-width: 2rem;
  max-width: 2rem;
}
@media (min-width: 1200px) {
  .country {
    flex-direction: row;
  }
  .country.country--small {
    width: 5rem;
  }
  .view--list .country {
    width: 2rem;
  }
}
.country.country--small {
  min-width: 4.5rem;
  max-width: 5rem;
}
.country__flag {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  background-color: transparent;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.view--list .country__flag {
  margin-right: 0;
}
.country__code {
  font-size: 1.1rem;
  text-transform: uppercase;
  color: #8b8b8b;
  letter-spacing: 1.01px;
}
.view--list .country__code {
  display: none;
}
.createdby {
  margin: 0 0 2rem 0;
}
@media (min-width: 768px) {
  .createdby {
    margin: 0;
  }
}
.createdby__detail {
  display: flex;
  line-height: 2.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #414141;
}
.createdby__detail.createdby__detail--email {
  color: #3ead47;
}
.createdby__detail svg {
  width: 1.8em;
  height: 1.8rem;
  margin-right: 1rem;
}
@media (min-width: 768px) {
  .createdby__detail svg {
    width: 1.4em;
    height: 1.4rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 768px) {
  .createdby__detail {
    line-height: 1.6rem;
    font-size: 1.2rem;
  }
}
.coursedetails {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.coursedetails__name {
  margin: 0;
  font-weight: 500;
  color: inherit;
}
.coursedetails__dates {
  font-size: 1.3rem;
  color: #414141;
}
.modal__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  box-sizing: border-box;
  text-size-adjust: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}
.modal__overlay.modal__active {
  opacity: 1;
  pointer-events: all;
}
.modal__active {
  width: 100vw;
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}
.modal__dialog {
  max-height: 96vh;
  max-width: 96vw;
  width: 96vw;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow:
    0 19px 60px rgba(0, 0, 0, 0.3),
    0 15px 20px rgba(0, 0, 0, 0.22);
  opacity: 0;
  overflow-y: scroll;
  transform: translateY(-40px);
  transition:
    opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.07s;
}
@media (min-width: 768px) {
  .modal__dialog {
    width: 50vw;
  }
}
.modal__dialog.modal__active {
  opacity: 1;
  transform: translateY(0%);
}
.modal__title {
  color: #000000;
  flex-grow: 0;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1;
  margin: 0 0 1.6rem;
}
.image-gallery-icon {
  color: #fff;
  transition: all 0.2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
}
@media (min-width: 768px) {
  .image-gallery-icon:hover {
    color: #3ead47;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #3ead47;
}
.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}
.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 36px;
  width: 36px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}
.image-gallery-fullscreen-button {
  right: 0;
}
.image-gallery-play-button {
  left: 0;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}
.image-gallery-left-nav {
  left: 0;
}
.image-gallery-right-nav {
  right: 0;
}
.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}
.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 110px);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(100% - 87px);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}
.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}
.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    padding: 8px 15px;
  }
}
.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: background 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus,
.image-gallery-bullets .image-gallery-bullet:hover {
  background: #3ead47;
  transform: scale(1.1);
}
.image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
}
.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}
.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  transition: transform 0.45s ease-out;
  white-space: nowrap;
}
.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #3ead47;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 3px solid #3ead47;
  }
}
.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}
.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}
