.dropzone {
  position: relative;
  padding: 4.8rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: box-shadow 220ms cubic-bezier(0.4, 0, 0.6, 1);
}
.dropzone.dropzone--simple {
  width: 100%;
  height: auto;
  padding: 0;
  border: 0;
}
.dropzone.dropzone--active {
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}
.dropzone.dropzone--forbidden {
  border: 1px solid #d00202;
}
.dropzone.dropzone--disabled {
  opacity: 0.3;
}
.dropzone.dropzone--disabled:hover {
  cursor: not-allowed;
}
.dropzone:hover {
  cursor: pointer;
}
.uploadicon {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition:
    opacity 220ms cubic-bezier(0.4, 0, 0.6, 1),
    transform 220ms cubic-bezier(0.4, 0, 0.6, 1);
}
.loader {
  position: absolute;
  opacity: 0;
  transform: scale3d(0, 0, 0);
  transition:
    opacity 220ms cubic-bezier(0.4, 0, 0.6, 1),
    transform 220ms cubic-bezier(0.4, 0, 0.6, 1);
}
.loader .loader--active {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.label {
  margin-bottom: 0;
  font-size: 14px;
  color: #aeaeae;
  transition:
    opacity 220ms cubic-bezier(0.4, 0, 0.6, 1),
    transform 220ms cubic-bezier(0.4, 0, 0.6, 1),
    color 220ms cubic-bezier(0.4, 0, 0.6, 1);
  text-align: center;
}
@media lg, (min-width: 992px) {
  .label {
    text-align: left;
  }
}
.label.label--active {
  opacity: 0;
}
.dropzone--forbidden .label {
  opacity: 0;
}
.forbidden {
  margin-bottom: 0;
  font-size: 14px;
  color: #d00202;
  transition:
    opacity 220ms cubic-bezier(0.4, 0, 0.6, 1),
    transform 220ms cubic-bezier(0.4, 0, 0.6, 1),
    color 220ms cubic-bezier(0.4, 0, 0.6, 1);
  text-align: center;
}
@media lg, (min-width: 992px) {
  .forbidden {
    text-align: left;
  }
}
.uploadcontent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dropzone--simple .uploadcontent {
  padding-top: 0;
}
.dropzone--active .uploadcontent .uploadicon {
  opacity: 0;
  transform: scale3d(0, 0, 0);
}
.dropzone--active .uploadcontent .label {
  opacity: 0;
}
